<template>
    <b-container fluid>
        <notifications group="category" />

        <b-card>
            <template #header>
                活動分類 - 列表
            </template>

            <b-row class="mb-2">
                <b-col>
                    <router-link tag="button" to="/admin/createCategory" class="btn btn-primary">
                        <b-icon-plus></b-icon-plus>
                        新增
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                :fields="fields"
                head-variant="light"
                hover
                sort-icon-left
                :items="categories">

                <template v-slot:cell(text)="{ item }">
                    <div class="category-item" v-if="item.text && item.text.zh" > <span class="title">中文:</span> {{ item.text.zh }} </div>
                    <div class="category-item" v-if="item.text && item.text.en"> <span class="title">英文:</span> {{ item.text.en }} </div>
                </template>

                <template v-slot:cell(edit)="{ item, index }">
                    <div class="text-right">
                        <b-icon
                            @click="handlePutCategory(item.value)"
                            :id="`edit-event${index}`"
                            font-scale="1.2"
                            class="event-icon"
                            icon="pencil">
                        </b-icon>
                        <b-tooltip :target="`edit-event${index}`" placement="left">
                            編輯
                        </b-tooltip>

                        <b-icon
                            @click="handleDeleteCategory(item.value)"
                            :id="`delete-event${index}`"
                            font-scale="1.2"
                            class="event-icon"
                            icon="trash"
                            >
                        </b-icon>
                        <b-tooltip :target="`delete-event${index}`" placement="left">
                            刪除
                        </b-tooltip>
                    </div>
                </template>
            </b-table>
        </b-card>
    </b-container>
</template>

<script>
import { getCategories, deleteCategory } from '@/apis/apiEventCategories';

export default {
    data() {
        return {
            fields: [
                {
                    key: 'text',
                    label: '分類',
                },
                {
                    key: 'edit',
                    label: '',
                }
            ],

            categories: []
        }
    },

    created() {
        this.initialView();
    },

    methods: {
        async initialView() {
            const categories = await getCategories();
            this.categories = categories;
        },

        handlePutCategory(categoryId) {
            this.$router.push({
                path: '/admin/putCategory',
                query: {
                    category: categoryId,
                },
            })
        },

        async handleDeleteCategory(id) {
            const result = await deleteCategory(id);

            if (result.status === 200) {
                this.$notify({
                    group: 'category',
                    type: 'success',
                    title: '成功刪除',
                    position: 'top center',
                });

                const categories = await getCategories();
                this.categories = categories;

                return;
            }

            this.$notify({
                group: 'category',
                type: 'warn',
                title: `${result.message}`,
                position: 'top center',
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .event-icon{
        color: #616161;
        cursor: pointer;
        margin: 0 6px;
    }

    .category-item{
        display: inline-flex;
        min-width: 150px;
        padding-right: 20px;
        align-items: center;

        .title{
            display: inline-block;
            color: #949494;
            font-size: 14px;
            font-weight: 500;
            padding-right: 10px;
        }
    }
</style>